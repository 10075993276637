import * as React from 'react';
import { createPortal } from 'react-dom';
import './Loader.scss';

interface ILoaderProps {
  useFullPage?: boolean;
  dimension?: DOMRect;
  withBackdrop?: boolean;
}

function Loader({
  dimension,
  useFullPage = true,
  withBackdrop = true,
}: ILoaderProps) {
  const parentHeight = dimension?.height;
  const parentWidth = dimension?.width;

  const getLoaderMarkup = () => (
    <div
      className={`loader-container ${
        useFullPage ? 'full-page-loader' : 'section-page-loader'
      } ${withBackdrop ? 'with-bd' : ''}`}
      style={!useFullPage ? { height: parentHeight, width: parentWidth } : {}}
    >
      <div className="animated-content">
        <div className="meetup">
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );

  if (!useFullPage) {
    return getLoaderMarkup();
  }

  return <>{createPortal(getLoaderMarkup(), document.body)}</>;
}

export default Loader;

import * as React from 'react';
import './KebabMenu.scss';
import lodash from 'lodash';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import MenuContents from './components/MenuContents';
import { KebabMenuItem } from './types';

interface KebabMenuProps {
  options: KebabMenuItem[];
  iconPosition: 'vertical' | ('horizontal' & string);
  onHideMenu?: () => void;
}
const menuIconCount = 3;
function KebabMenu({
  options,
  iconPosition = 'vertical',
  onHideMenu,
}: KebabMenuProps) {
  const contentsAttachRef = React.useRef<HTMLDivElement>(null);
  const [showMenuContents, setShowMenuContents] =
    React.useState<boolean>(false);
  const hideMenuContents = React.useCallback(() => {
    setShowMenuContents(false);
    onHideMenu?.();
  }, []);
  return (
    <div className="kebab-menu-container">
      <div
        role="button"
        tabIndex={0}
        className={`menu-trigger ${iconPosition}`}
        ref={contentsAttachRef}
        onClick={() => setShowMenuContents((prev) => !prev)}
      >
        {lodash.range(menuIconCount).map((index) => (
          <div className="menu-icon" key={index} />
        ))}
      </div>

      {
        createPortal(
          <AnimatePresence>
            {showMenuContents && (
              <MenuContents
                menuItems={options}
                attachRef={contentsAttachRef}
                hideMenuContents={hideMenuContents}
              />
            )}
          </AnimatePresence>,
          document.body,
        ) as React.ReactNode
      }
    </div>
  );
}

export default KebabMenu;

import * as React from 'react';
import { createPortal } from 'react-dom';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { ApiDateFormat } from '@utilities/common/Date';
import './DatePicker.scss';
import useWindowDimensions from '@hooks/useWindowDimensions';
import DatePickerInput from './components/DatePickerInput';
import DatePickerPopper from './components/DatePickerPopper/DatePickerPopper';

interface DatePickerProps {
  onSelect: (date: string) => void;
  enabledDates?: string[];
  initialTransFormPos?: React.CSSProperties['translate'];
  disabledDates?: string[];
}

function DatePicker({
  onSelect,
  enabledDates,
  initialTransFormPos,
  disabledDates,
}: DatePickerProps) {
  const popperSideMargin = 16; // in px
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [parentRect, setParentRect] = React.useState<DOMRect | undefined>();
  const [selectedDate, setSelectedDate] = React.useState<string>('');
  const [showDatePickerPopper, setShowDatePickerPopper] =
    React.useState<boolean>(false);
  const { width, height } = useWindowDimensions();

  const onSelectDate = (date: string) => {
    setSelectedDate(date);
    onSelect(date);
  };

  React.useEffect(() => {
    if (parentRef.current) {
      const rect = parentRef.current?.getBoundingClientRect();
      setParentRect(rect);
    }
  }, [parentRef.current]);

  return (
    <div className="date-picker-container" ref={parentRef}>
      <DatePickerInput
        onClick={() => setShowDatePickerPopper(true)}
        value={
          selectedDate &&
          moment(selectedDate, ApiDateFormat).format('MMM DD, YYYY - dddd')
        }
      />
      {
        createPortal(
          <AnimatePresence>
            {showDatePickerPopper && parentRect && (
              <DatePickerPopper
                enabledDates={enabledDates}
                disabledDates={disabledDates}
                top={width > 1000 ? parentRect.top || 0 : height * 0.35}
                left={
                  width > 1000
                    ? parentRect.right + popperSideMargin || 0
                    : parentRect.left + popperSideMargin
                }
                onSelectDate={(date) => onSelectDate(date)}
                onCancel={() => setShowDatePickerPopper(false)}
                transformPosOnMounting={initialTransFormPos}
              />
            )}
          </AnimatePresence>,
          document.body,
        ) as React.ReactNode
      }
    </div>
  );
}

export default DatePicker;

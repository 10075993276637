import { ProviderRoles } from '@constants/provider';
import { FetchKeys } from '@constants/queries';
import { IProviderService } from '@customTypes/response/provider';
import useAuth from '@hooks/useAuth';
import { PromiseCallbacks, useFetch } from '@hooks/useFetch';
import { getProviderServices } from '@services/provider';

function useProviderServices(
  providerId: string,
  role: string,
  { onSuccess }: PromiseCallbacks,
) {
  const {
    user: { isCareNavigator },
  } = useAuth();

  return useFetch<IProviderService>(
    [FetchKeys.providerServices, providerId],
    () => getProviderServices(providerId),
    {
      onSuccess,
      refetchOnWindowFocus: false,
      enabled: isCareNavigator && role === ProviderRoles.INTELLECTCLINICOFFSITE,
    },
  );
}

export default useProviderServices;

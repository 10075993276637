import * as React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@widgets/Typography';
import IconText from '@components/IconText';
import calendar from '@assets/onsite-calendar.svg';
import locationPin from '@assets/location-pin.svg';
import './EventDetailsScreen.scss';
import Carousel from '@widgets/Carousel';
import OnsiteCardLayout from '@components/OnsiteCardLayout';
import {
  useAllBookings,
  useOnsiteDeployement,
} from '@hooks/useOnsiteData/useOnsiteData';
import {
  getOnsiteEventTimings,
  getStartEndDatesForOnsiteSession,
  getTimeZone,
  OnsiteDateFormat,
  slotTo15MinTime,
} from '@utilities/common/Date';
import Loader from '@widgets/Loader';
import {
  IDeploymentEventObject,
  ISessionObj,
} from '@hooks/useOnsiteData/types';
import { MeetingStatus, OnEventSiteStatus } from '@constants/meetingStatus';
import useTracking from '@hooks/useTracking';
import {
  EventActions,
  EventCategories,
  EventNames,
} from '@constants/analytics';
import Analytics from '@services/Analytics';
import { openExternalUrl } from '@utilities/common/Path';
import CounselingCard from './CounselingCard';
import backIcon from '../../../assets/go-back.svg';
import riseIcon from '../../../assets/RiseSessionLogo.svg';
import wellBeingIcon from '../../../assets/wellbeingLogo.svg';
import useProfileDetails from '../Provider/Home/hooks/useProfileDetails';
import ProviderDetails from './ProviderDetails';
import OnsiteBookCancelPopUp from '../OnsiteBookCancelPopUp/OnsiteBookCancelPopUp';

export default function EventDetailsScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { track } = useTracking();
  const [showOnsiteModal, setShowOnsiteModal] = React.useState<boolean>(false);
  const filters = [
    {
      field: 'status',
      value: [MeetingStatus.scheduled],
    },
    {
      field: 'onsiteId',
      value: [eventId],
    },
  ];

  const { meetings, isLoading, refetchAllBookings } = useAllBookings(filters);

  const {
    onsiteDeploymentData,
    isOnsiteDeploymentDataLoading,
    refetchOnsiteDeploymentData,
  } = useOnsiteDeployement(eventId as string);

  const { data: profileData, isLoading: isLoadingProfile } = useProfileDetails({
    refetchOnWindowFocus: false,
  });
  const {
    events,
    location,
    provider,
    fromDate,
    fromTime,
    toDate,
    toTime,
    expertise,
    status,
    providerOverview,
  } = onsiteDeploymentData || {};

  const onBackButtonClick = () => {
    navigate(-1);
  };
  const getCarouselElements: () => React.ReactNode[] | undefined = () => {
    const similarEvents = events?.reduce(
      (
        acc: (ISessionObj & {
          eventType: string;
          sessionStartTime: string;
          sessionStartTimeInUTC: number;
        })[],
        curr: IDeploymentEventObject,
      ) => {
        if (curr.eventType !== 'one_to_one_councelling') {
          return [
            ...acc,
            ...curr.sessions.map((session) => {
              const { startDate } = getStartEndDatesForOnsiteSession({
                onsiteStartDate: fromDate ?? '',
                onsiteStartSlot: fromTime ?? '',
                onsiteEndDate: toDate ?? '',
                onsiteEndSlot: toTime ?? '',
                sessionStartSlot: session.from,
                sessionEndSlot: session.to,
              });

              const sessionStartTime = moment
                .utc(
                  `${startDate ?? fromDate} ${slotTo15MinTime(
                    +(session.from || fromTime || '0'),
                  )}`,
                  'YYYY-MM-DD HH:mm',
                )
                .tz(getTimeZone());

              const sessionConvertedTime = `${sessionStartTime.format(
                OnsiteDateFormat,
              )} | ${sessionStartTime.format('hh:mm A z')}`;

              return {
                ...session,
                eventType: curr.eventType,
                sessionStartTime: sessionConvertedTime,
                sessionStartTimeInUTC: sessionStartTime.unix(),
              };
            }),
          ];
        }
        return acc;
      },
      [],
    );
    return similarEvents
      ?.sort(
        (a: any, b: any) => a.sessionStartTimeInUTC - b.sessionStartTimeInUTC,
      )
      ?.map((item) => (
        <OnsiteCardLayout
          cardWidth="619px"
          isDeployementCard={false}
          cardTitle={item.topics}
          dateIcon={calendar}
          locationIcon={locationPin}
          onClick={() => openExternalUrl(item.redirectUrl)}
          eventIcon={item.eventType === 'rise' ? riseIcon : wellBeingIcon}
          eventLocation={item.room}
          isLoading={isOnsiteDeploymentDataLoading}
          eventTimings={`${item.sessionStartTime}`}
          cardContent={item.overview}
          redirectUrl={item.roomGuide}
          disabled={status !== OnEventSiteStatus.LIVE}
        />
      ));
  };

  const carouselElements = React.useMemo(getCarouselElements, [
    events,
    isOnsiteDeploymentDataLoading,
  ]);

  const counsellingSession = React.useMemo(
    () =>
      events &&
      events.find((event) => event.eventType === 'one_to_one_councelling'),
    [events],
  );
  const counsellingLocationInfo = React.useMemo(
    () =>
      // if (counsellingSession && counsellingSession?.sameRoom) {
      //   return `${counsellingSession?.sessions[0]?.room}`;
      // } // open in case room location is required instaed of office
      `${location?.address}, ${location?.city}`,
    [counsellingSession, location],
  );

  const counsellingSessionStartingTimings = React.useMemo(() => {
    const CounsellingEvents =
      counsellingSession?.sessions
        ?.map((session) => {
          const { startDate } = getStartEndDatesForOnsiteSession({
            onsiteStartDate: fromDate ?? '',
            onsiteStartSlot: fromTime ?? '',
            onsiteEndDate: toDate ?? '',
            onsiteEndSlot: toTime ?? '',
            sessionStartSlot: session.from,
            sessionEndSlot: session.to,
          });
          const momentTime = moment
            .utc(
              `${startDate} ${slotTo15MinTime(+session.from)}`,
              'YYYY-MM-DD HH:mm',
            )
            .tz(getTimeZone());

          return {
            ...session,
            sessionStartTimeInUTC: momentTime.unix(),
          };
        })
        ?.sort((a, b) => a.sessionStartTimeInUTC - b.sessionStartTimeInUTC) ??
      [];

    const { sessionStartTimeInUTC } = CounsellingEvents?.[0] ?? {};

    if (!sessionStartTimeInUTC) return '';

    return `${moment
      .unix(sessionStartTimeInUTC)
      .tz(getTimeZone())
      .format(OnsiteDateFormat)} | ${moment
      .unix(sessionStartTimeInUTC)
      .tz(getTimeZone())
      .format('hh:mm A z')} onwards`;
  }, [counsellingSession, fromDate, fromTime, toDate, toTime]);

  const onsiteWhenAndWhereTimings = React.useMemo(() => {
    if (counsellingSession) {
      return counsellingSessionStartingTimings;
    }
    return `${moment
      .utc(
        `${fromDate} ${slotTo15MinTime(+(fromTime || '0'))}`,
        'YYYY-MM-DD HH:mm',
      )
      .tz(getTimeZone())
      .format(OnsiteDateFormat)} | ${getOnsiteEventTimings(
      fromDate as string,
      fromTime as string,
    )} onwards`;
  }, [counsellingSession, fromDate, fromTime]);

  const availableSlotData = React.useMemo(() => {
    if (counsellingSession) {
      return counsellingSession?.slots?.map((item) => ({
        label: moment
          .utc(item.startTimeUtc * 1000)
          .tz(getTimeZone())
          .format('hh:mm A'),
        displayDate: moment
          .utc(item.startTimeUtc * 1000)
          .tz(getTimeZone())
          .format('DD MMM'),
        value: item.startTimeUtc.toString(),
        disabled: !item.active,
      }));
    }
    return [];
  }, [counsellingSession]);

  const couselingCardClickHandler = () => {
    const noSlots = availableSlotData?.length === 0;
    const alreadyBooked = meetings && meetings?.length > 0;
    if (noSlots && !alreadyBooked) {
      const element = document.getElementById('other-events');
      element?.scrollIntoView({ behavior: 'smooth' });
      track(EventNames.exploreEvents, {
        eventAction: EventActions.click,
        eventCategory: EventCategories.exploreEvents,
        eventLabel: 'explore_events',
        userType: Analytics.getUserType(),
        eventSource: 'onsite',
      });
      return;
    }
    track(alreadyBooked ? EventNames.editSession : EventNames.bookSession, {
      eventAction: EventActions.click,
      eventCategory: alreadyBooked
        ? EventCategories.editSession
        : EventCategories.bookSession,
      eventLabel: alreadyBooked ? 'edit_session' : 'book_session',
      userType: Analytics.getUserType(),
      eventSource: 'onsite',
    });
    setShowOnsiteModal(true);
  };

  if (isOnsiteDeploymentDataLoading || isLoadingProfile || isLoading) {
    return <Loader />;
  }

  return (
    <article className="event-details-wrapper">
      <section className="event-header">
        <section
          role="button"
          className="back-cta"
          tabIndex={0}
          onClick={() => onBackButtonClick()}
        >
          <img src={backIcon} alt="go back" />
        </section>
        <Typography size={24} weight="500">
          T&S Specialized Care Onsite Days
        </Typography>
        <Typography size={16} weight="400" withColor="#6c6d6e">
          {t('EVENT_INTRO')}
        </Typography>
      </section>
      {events &&
        events?.findIndex(
          (event) => event?.eventType === 'one_to_one_councelling',
        ) > -1 && (
        <CounselingCard
          providerName={provider?.name as string}
          onClickHandler={couselingCardClickHandler}
          slots={availableSlotData}
          isSessionBooked={meetings && meetings?.length > 0}
          eventStartTime={moment
            .unix(Number(meetings?.[0]?.scheduledStartTime))
            .format('hh:mm A')}
          eventEndTime={moment
            .unix(Number(meetings?.[0]?.scheduledEndTime))
            .format('hh:mm A')}
          disabled={status !== OnEventSiteStatus.LIVE}
          showCTA={
            !!carouselElements?.length ||
              !!meetings?.length ||
              !!availableSlotData?.length
          }
        />
      )}
      <section className="location-timing-wrapper">
        <Typography size={24} weight="500">
          When & Where
        </Typography>
        <section className="location-timing">
          <IconText
            icon={calendar}
            text={`${onsiteWhenAndWhereTimings}`}
            fontColor="#6c6d6e"
            iconHeight="24px"
            iconWidth="24px"
            variant="with-background"
            textFontSize={16}
            className="customized-gap"
            textWidth="50%"
          />
          <IconText
            icon={locationPin}
            text={counsellingLocationInfo}
            fontColor="#6c6d6e"
            iconHeight="24px"
            iconWidth="24px"
            variant="with-background"
            textFontSize={16}
            className="customized-gap"
            redirectIconColor="#6A6B6D"
            textWidth="auto"
          />
        </section>
      </section>
      <ProviderDetails
        provider={provider}
        providerExpertise={expertise}
        providerOverview={providerOverview}
      />
      {!!carouselElements?.length && (
        <Carousel
          id="other-events"
          carouselItem={carouselElements}
          slidesToShow={window.innerWidth > 768 ? 2 : 1}
          title="Explore other services"
        />
      )}
      {showOnsiteModal && (
        <OnsiteBookCancelPopUp
          eventId={eventId as string}
          counsellingStartTiming={counsellingSessionStartingTimings}
          counsellingLocationInfo={`${location?.address}, ${location?.city}`}
          providerId={provider?.providerId?.toString()}
          isRescheduleFlow={meetings && meetings?.length > 0}
          meetingId={meetings?.[0]?.id}
          phoneNumber={profileData?.phoneNumber as string}
          providerName={provider?.name as string}
          onclose={() => {
            refetchAllBookings();
            refetchOnsiteDeploymentData();
            setShowOnsiteModal(false);
          }}
        />
      )}
    </article>
  );
}
